<template>
<div class="container">
  <div class="margin-top-120"></div>
  <div class="row">
    <div class="col-xs-12 col-sm-2 col-md-3"></div>
    <div class="col-xs-12 col-sm-8 col-md-6">
      <form @submit.prevent="handleSubmit">
        <div class="form-group">
          <label>书籍名称</label>
          <input type="text" class="form-control" v-model="title" id="title" aria-describedby="emailHelp">
          <small id="title" class="form-text text-muted"></small>
        </div>

        <div class="form-group">
          <label>系统随机图片</label>
        </div>

        <div class="form-group">
          <label>书籍描述</label>
          <textarea class="form-control" id="exampleFormControlTextarea1" rows="5" v-model="body"></textarea>
        </div>
        
        <button type="submit" class="btn btn-primary submit-right">提交</button>
      </form>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Login',
  data() {
    return {
      title: '',
      body: '',
      remember_me: false,
    }
  },
  methods: {
      async handleSubmit() {
        // console.log(this.title);
        // console.log(this.body);
        const response = await axios.post('/api/web/auth/book/create', {
          title: this.title,
          body: this.body,
        });

        // console.log(response)
        
        if(response.data.code == 200) {
          localStorage.setItem('token', response.data.data.token);
          // console.log(localStorage.getItem('token'));
          // this.$router.push('/');
          // window.location.replace("/");
        } else {
          alert(response.data.message)
        }
        
      }
    }
}
</script>
